<template>
  <div class="HotContainer">
    <div class="title">
      <img class="title-icon" src="../../assets/images/信息资讯.png" alt="" />
      <span class="title-word">热门资讯</span>
    </div>
    <div class="hotContent">
      <div
        class="hotItem"
        v-for="(item, index) in hotList"
        :key="index"
        @click="toInfoDetails(item.billid)"
      >
        <div>
          <el-image class="img" alt="" :fit="'fill'" :src="item.thumb" />
        </div>
        <div class="itemContent">
            {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getHotBizinfos } from "../../api/info/info.js";
export default {
  computed: {
    ...mapGetters(["topHotInfo"]),
  },
  data() {
    return {
      hotList: [],
    };
  },
  created() {
    this.hotList = this.topHotInfo;
    console.log("热门咨询的数据:",this.hotList)
    if (this.topHotInfo.length === 0) {
      this.getHotBizinfos();
    }
  },
  methods: {
    async getHotBizinfos() {
      let that = this;
      this.$store
        .dispatch("SetTopInfo")
        .then((res) => {
          let d = res.data;
          console.log(d)
          that.hotList = d.slice(0, 4);
        })
        .catch(() => {});
    },
    toInfoDetails(id) {
      this.$emit("jumpDetails", id);
    },
  },
};
</script>
<style scoped lang="scss">
.HotContainer {
  width: 100%;
  height: 544px;
  // border-top: 4px solid #14569f;
  box-sizing: border-box;
  // padding: 24px 20px;
  // border-right: 1px solid #eaeaea;
  // border-bottom: 1px solid #eaeaea;
  // border-left: 1px solid #eaeaea;
  padding-bottom: 25px;
  overflow: hidden;
  // .img {
  //   width: 120px;
  //   height: 90px;
  //   // border: 1px solid #ddd;
  //   margin-right: 15px;
  // }
  .hotContent {
    // padding-top: 25px;
    overflow-y: auto;
    height: 312px;
    width: 320px;
  }
  .title {
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #14569f;
    width: 300px;
    height: 38px;
    padding-left: 30px;
    line-height: 38px;
    // height: 72px;
    .title-icon {
      width: 8px;
      height: 10px;
      margin-right: 8px;
    }

    .title-word {
      color: #101010;
      font-size: 16px;
    }
  }
  .hotItem {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    height: 78px;
    width: 300px;
    padding: 10px 30px;
    .img {
      height: 58px;
      width: 98px;
      border-radius: 6px;
    }
    .itemContent {
      font-size: 14px;
      box-sizing: border-box;
      line-height: 22px;
      width: 130px;
      padding: 0 12px;
      text-overflow: ellipsis;
      // white-space: nowrap;
      -webkit-line-clamp:2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      &:hover {
        color: #2885FF;
      }
    }
  }
}
</style>