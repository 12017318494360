<template>
  <div class="Classify">
    <div class="title">
      <img class="title-icon" src="../../assets/images/信息资讯.png" alt="" />
      <span class="title-word">资讯分类</span>
    </div>
    <div class="classifyContent">
      <div
        v-for="(item, index) in bizColumnsList"
        :key="index"
        @click="selectTagHandle(item.billid)"
        class="item"
      >
        <div class="itemBlock">
          <div class="icon-box" :style="{backgroundColor: imgMap[item.type_name]}">
            <img
              :src="
                imgMap[item.type_name]
                  ? require('../../assets/images/' + item.type_name + '.png')
                  : require('../../assets/images/' + '空白图片' + '.png')
              "
              alt=""
              class="icon"
            />
          </div>
          <div class="text">
            {{ item.type_name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getBizcolumns } from "../../api/info/info.js";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["bizColumnsList"]),
  },
  data() {
    return {
      imgMap: {
        体育频道: "#E4EDFF",
        国内新闻: "#FFF2E8",
        时政新闻: "#FFF3DE",
        国际新闻: "#E5F9F8",
        教育频道: "#E3F5FE",
      },
    };
  },
  created() {
    // this.getData();
  },
  methods: {
    // async getData() {
    //   let res = await getBizcolumns();
    //   this.classifyList = res.data;
    // },
    selectTagHandle(type) {
      this.$emit("selectTag", type);
    },
  },
};
</script>
<style scoped lang="scss">
.Classify {
  min-width: 300px;
  width: 300px;
  box-sizing: border-box;
  padding-top: 24px;
  .title {
    height: 38px;
    width: 100%;
    font-size: 16px;
    padding-left: 30px;
    // font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-family: 思源黑体 CN Medium;
    font-weight: 500;
    color: #14569f;
    // text-align: center;
    line-height: 38px;
    .title-icon {
      width: 8px;
      height: 10px;
      margin-right: 8px;
    }
    .title-word {
      color: #101010;
      font-size: 16px;
    }
  }
}

.classifyContent {
  cursor: pointer;
  // height: 160px;
  width: 100%;
  display: flex;
  // justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  // padding: 0px 12px;
  box-sizing: border-box;
}
.item {
  box-sizing: border-box;
  width: 100px;
  height: 80px;
  padding: 0 26px;
  // margin-right: 16px;
  // margin-left: 16px;
  .itemBlock {
    // background: #e1efff;
    box-sizing: border-box;
    width: 48px;
    padding: 10px 0;
    // height: 80px;
    // padding: 10px 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon-box {
      box-sizing: border-box;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      background-color: #e4edff;
      margin-bottom: 8px;
      &:-tiyupindao {
        background-color: #e4edff;
      }
      .icon {
        height: 22px;
        width: 22px;
        // margin-right: 20px;
      }
    }

    .text {
      text-align: center;
      height: 12px;
      width: 48px;
      font-size: 12px;
      // font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-family: 思源黑体 CN Medium;
      font-weight: 500;
      color: #101010;
    }
  }
}
.classifyContent > div:hover {
  color: rgb(2, 167, 240);
}
</style>